import React, { useEffect } from 'react'
import './aboutcompany.css'
import { useLocation } from 'react-router-dom';
import DataScanning from '../../../Assets/Data-entry.jpg'
import DataProcessing from '../../../Assets/dataprocessing.jpg'
import Company from '../../../Assets/BinarySystemsOfficeOutside.jpeg'
import PhotoImaging from '../../../Assets/photo-imaging.jpg'
import PVC from '../../../Assets/pvcCard.jpg'
const AboutCompany = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='company-header'>
            <p className="main">About Us</p>
            <div className="company-hero">
                <div className="company-left">
                    <h1>Designing <br />Identification  <br /><span>Card Solutions</span></h1>
                    <p>Binary Systems (GST No. 08ABEPA9306L1ZN) and (PAN No. ABEPA9306L) a proprietorship Firm and has it registered office at H-903, Sitapura Industrial Area, Phase-3, Jaipur – 302022 (Rajasthan)</p>
                    {/* <a href="#">Learn more</a> */}
                </div>
                <div className="company-right">
                    <img src={Company} alt="Company-Image" />
                </div>
            </div>
            <div className="title-section">

                <p className="main">Our Services</p>
            </div>

            <div className="services-main-block">
                <div id="services-main-row">
                    <div className="service-column">
                        <img src={DataScanning} alt='Bulk Data Entry and Scanning' />
                        <h1>Bulk Data Entry and Scanning</h1>
                        <p>Bulk Data Entry and Scanning services efficiently convert large volumes of physical documents into digital formats, facilitating streamlined data management for organizations.</p>
                        <a href="/data-entry" title="IT Solutions Button">LETS GET DIGITALIZE</a>
                    </div>
                    <div className="service-column">
                        <img src={DataProcessing} alt='Data Processing' />
                        <h1>Data Processing</h1>
                        <p>We excel in data processing, transforming raw data into actionable insights for informed decision-making and operational optimization.</p>
                        <a href="/data-entry" title="Consulting Button">LET'S GET COMPUTE</a>
                    </div>
                    <div className="service-column">
                        <img src={PhotoImaging} alt='Photo Imaging & Bar/QR Coding' />
                        <h1> Photo Imaging & Bar/QR Coding</h1>
                        <p>Our services include expert photo imaging and precise Bar/QR coding, ensuring seamless integration of visual data into digital systems for enhanced accessibility and functionality.</p>
                        <a href="/scanning" title="Design & Marketing Button">LET'S GET STARTED</a>
                    </div>
                    <div className="service-column">
                        <img src={PVC} alt='Manufacturing of PVC Cards' />
                        <h1>Manufacturing of PVC Cards</h1>
                        <p>We manufacture high-quality  Smart Cards, RFID Cards, Security Printer with complete solutions for both government and private sector organizations</p>
                        <a href="/pvc-card" title="Security Button">LET'S GET SECURE</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutCompany

import React from 'react'
import Topfold from './Topfold/Topfold'
import Connect from '../ContactSection/Connect'
import { useNavigate } from "react-router-dom"
import AboutCompany from './TheCompany/AboutCompany'
import Qualities from './Qualities/Qualities'

const AboutUs = () => {
    let navigate = useNavigate()
    return (
        <div className='about-us'>
            <Topfold />
            <AboutCompany />
            <Qualities />
            <Connect />
        </div>
    )
}

export default AboutUs

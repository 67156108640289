import React, { useEffect, useState } from 'react';
import './serviceContainer.css'; // Import your CSS file
import Image1 from '../../../Assets/PVC.jpg';
import Image2 from '../../../Assets/DataEntry.jpg';
import Image3 from '../../../Assets/VariablePrinting.jpg';
import Image4 from '../../../Assets/ERP.jpg';
import Image5 from '../../../Assets/Scanning.jpg';
import Image6 from '../../../Assets/Governance.jpg';
import { useNavigate } from 'react-router-dom';
const services = [
  { image: Image1, title: 'PVC/RFID Card manufacturing', description: 'Your key to secure and smart access, crafted with precision ' },
  { image: Image2, title: 'Data Entry / Processing', description: 'Turning data into possibilities – accurately, efficiently, effectively' },
  { image: Image3, title: 'Variable Printing', description: 'Personalized printing solutions that speak directly to your audience' },
  { image: Image4, title: 'ERP', description: 'Preserving the past, enabling the future – digitization for a paperless world' },
  { image: Image5, title: 'Data Scanning/Digitalization', description: 'Integrate, innovate, and elevate your business operations with custom ERP solutions' },
  { image: Image6, title: 'E-Governance Services', description: 'Empowering governance, engaging citizens – digital solutions for public service excellence' },
];

const ServiceBox = ({ image, title, description,handleClick }) => {
  let navigate = useNavigate()

  const isMobile = window.innerWidth <= 1024;

 
    return (
      <div>
          <div className="service-box" onClick={() => handleClick(title)}>
        <img src={image} alt={title} />
        <p className="service-name">{title}</p>
      </div>
      {isMobile &&
      <div style={{display:'flex', flexDirection:'column'}}>
      <div className='service-desc'> {description} </div>
      <button className='service-btn' onClick={() => navigate("/services")}>Read more</button>
      </div>
      }
      </div>
  
    );
  };
  
  
  const ServiceContainer = () => {
    let navigate = useNavigate()

    const [selectedService, setSelectedService] = useState(null);
  
    const handleClick = (title) => {
      setSelectedService(services.find(service => service.title === title));
    };
  
  
    return (
      <div className="container">
        <div className="left">
          <h2>{selectedService ? selectedService.title : "Select a Service"}</h2>
          {selectedService && (
            <>
              <p>{selectedService.description}</p>
              <button className='left-button' onClick={() => navigate("/services")}>Know More</button>
            </>
          )}
        </div>
        <div className="right">
          <h2>Our  Services</h2>
          <div className="service-container">
            {services.map((service, index) => (
              <ServiceBox key={index} image={service.image} title={service.title} description={service.description} handleClick={handleClick} />
            ))}
          </div>
        </div>
      </div>
    );
  };
  
  export default ServiceContainer;
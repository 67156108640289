import React from 'react'
import './governance.css'
const EGovernance = () => {
    return (
        <div className='e-governance'>
            <h1>E-Governance Services</h1>
            <div className="description">
                <div className="column">
                    <div className="card">
                        <div className="icon-wrapper">
                            <i className="fas fa-handshake"></i>
                        </div>
                        <h3>
                            Empowering Government-Citizen Interaction</h3>
                        <p>
                            With our E-Governance services we assist government interactions with citizens (G2C), businesses (G2B), employees (G2E) and help coordinate between the government departments and ministries (G2G), thus boosting quality of services and management of resources to ensure convenience and transparency within the system.
                        </p>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="icon-wrapper">
                            <i className="fas fa-cogs"></i>
                        </div>
                        <h3>Maximizing Technological Capabilities</h3>
                        <p>
                            Improved technology revolutionizes government processes and infrastructure, boosting productivity and operational efficiency. Our solutions optimize resource utilization, enabling governments to better serve constituents.
                        </p>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="icon-wrapper">
                            <i className="fas fa-users"></i>
                        </div>
                        <h3>Service Enhancement for Citizens</h3>
                        <p>
                            Technology transforms government-citizen interaction, facilitating effortless connectivity and information access. Silver Touch empowers governments to enhance engagement and connectivity with citizens through a range of services.
                        </p>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="icon-wrapper">
                            <i className="fas fa-globe"></i>
                        </div>
                        <h3>Global Connectivity and Emerging Technologies</h3>
                        <p>
                            In the era of globalization and technological advancement, governments seek innovative ways to connect with citizens. Our services help governments leverage emerging technologies to meet evolving citizen needs.
                        </p>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="icon-wrapper">
                            <i className="fas fa-lightbulb"></i>
                        </div>
                        <h3>Silver Touch Solutions for Government Empowerment</h3>
                        <p>
                            We provide a suite of solutions enabling governments to connect effectively with citizens. From digital communication platforms to secure information access systems, our services support modern governance.
                        </p>
                    </div>
                </div>
                <div className="column">
                    <div className="card">
                        <div className="icon-wrapper">
                            <i className="fas fa-balance-scale"></i>
                        </div>
                        <h3>Driving Citizen-Centric Governance</h3>
                        <p>
                            Silver Touch empowers governments to deliver citizen-centric governance through meaningful interactions and exceptional services. Our collaborative approach and cutting-edge solutions build stronger, responsive communities.
                        </p>
                    </div>
                </div>
                
            </div>
            <div className='about-services'>
                <p>
                E-Mitra is a motivated E-governance proposal of Government of Rajasthan, which is being implemented in districts using Public-Private Partnership (PPP) for the expediency and clarity to the citizen to deliver various services with ease and readiness. The objective of e-Mitra is to provide wide range of services pertaining to different departments under one umbrella through an e-platform of emitra online payment so that every citizen receives every service with ease and contempt.
                </p>
                <p>
                Binary Systems is working as Local service Provider (LSP) by Raj comp Info services Ltd. Currently running 1100 kiosks in Jaipur, Dausa, Swaimadhopur, Alwar districts.. Binary online portal provides you an easy access to all our E-Mitra services and to provide everything with ease.
                </p>
            </div>
            
        </div>
    )
}

export default EGovernance
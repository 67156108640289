// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html{
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background: #fff;
  font-family: 'Open Sans', sans-serif;
  color:black;
}
.whatsapp{
  position: fixed;
  bottom: 20px;
  right: 20px;
}
.whatsapp{
  margin: 0 0 20px 0 !important;
  height: 57px;
  width: 57px;
  padding: 8px 0px 0px 9px;
    position: fixed !important;
    bottom: 10px;
    right: 20px;
    z-index: 999 !important;
    background-color:#42a5f6;
    box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.4);
    border-radius: 100px;
}
._cUP1np9gMvFQrcPftuf._Y8HCTxgNkwxXcG_DCXx,.Pl9F4D0kOr1s0KUQ1VhD.PRlx_aGn2CIKvSPG5Y3G{
  margin-top:9% !important;
}
.nX1mqja_7P44IZA6HzKt.QnoSECjNGJyoVwZeO1Cp{
  width: 40% !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,uBAAuB;AACzB;AACA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,WAAW;AACb;AACA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;AACb;AACA;EACE,6BAA6B;EAC7B,YAAY;EACZ,WAAW;EACX,wBAAwB;IACtB,0BAA0B;IAC1B,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,wBAAwB;IACxB,2CAA2C;IAC3C,oBAAoB;AACxB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,qBAAqB;AACvB","sourcesContent":["html{\n  scroll-snap-type: y mandatory;\n  scroll-behavior: smooth;\n}\n*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\nbody{\n  background: #fff;\n  font-family: 'Open Sans', sans-serif;\n  color:black;\n}\n.whatsapp{\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n}\n.whatsapp{\n  margin: 0 0 20px 0 !important;\n  height: 57px;\n  width: 57px;\n  padding: 8px 0px 0px 9px;\n    position: fixed !important;\n    bottom: 10px;\n    right: 20px;\n    z-index: 999 !important;\n    background-color:#42a5f6;\n    box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.4);\n    border-radius: 100px;\n}\n._cUP1np9gMvFQrcPftuf._Y8HCTxgNkwxXcG_DCXx,.Pl9F4D0kOr1s0KUQ1VhD.PRlx_aGn2CIKvSPG5Y3G{\n  margin-top:9% !important;\n}\n.nX1mqja_7P44IZA6HzKt.QnoSECjNGJyoVwZeO1Cp{\n  width: 40% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import './quality.css'
import Vision from '../../../Assets/Vision.jpg'
import Values from '../../../Assets/Values.jpg'
import SecurityPolicy from '../../../Assets/SecurityPolicy.jpg'
import QualityPolicy from '../../../Assets/Qualitypolicy.jpg'
const Qualities = () => {
    return (
        <>
        <div className='quality-guard'>
            <div className='quality-cards'>
                <h1>VISION</h1>
                <img src={Vision} alt='Vision' />
                <p>
                    Our vision at Binary Systems is to set the global standard for secure and versatile card technology. We aim to empower businesses and consumers alike with cards that are not only tools for transactions but also symbols of adaptability and innovation in the digital age.
                </p>
            </div>
            <div className='quality-cards'>
                <h1>VALUES</h1>
                <img src={Values} alt='Values' />
                <p>
                    
                  <b>Innovation:</b>   We pioneer advancements in card technology.
                    <br /> <b>Customer Commitment:</b>  Your trust is our most valued asset.
                    <br /> <b>Integrity: </b> We practice business with honesty and honor.
                    <br /> <b>Teamwork: </b> Together, we create solutions that shape the future.

                </p>
            </div>
        </div>
        <div className='quality-guard'>
            <div className='quality-cards'>
                <h1>QUALITY Control</h1>
                <img src={QualityPolicy} alt='Quality Policy' />
                <p>
                Our commitment to quality is uncompromising. From selecting premium materials to conducting rigorous testing, every Binary Systems card is a testament to our dedication to excellence. Our quality control ensures that every product meets the highest standards of durability, reliability, and performance

                </p>
            </div>
            <div className='quality-cards'>
                <h1>Security Policy</h1>
                <img src={SecurityPolicy} alt='Security Policy' />
                <p>
                Binary Systems is synonymous with security. Our security policy involves multi-layered protocols to protect data and ensure the integrity of every card. We are constantly evolving our methods to stay ahead of threats and provide you with products you can trust implicitly.
                </p>
            </div>
        </div>
        
        </>
    )
}

export default Qualities

import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./navbar.css";
import Logo from '../../Assets/BinarySystemsLogo.png';
import { useNavigate, useLocation } from "react-router-dom";

function Navbar() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="header">
      <div className='logo'>
        <img src={Logo} alt='' onClick={()=>navigate("/")} style={{cursor:'pointer'}}/>
      </div>
      <nav ref={navRef}>
        {isSmallScreen ? (
          <>
            <a href="/home" className={isActive("/home") ? "active" : ""}>Home</a>
            <a href="/about-us" className={isActive("/about-us") ? "active" : ""}>About Binary Systems</a>
            <a href="/services" className={isActive("/services") ? "active" : ""}>Services we offer</a>
            <a href="/projects" className={isActive("/projects") ? "active" : ""}>Our Major Projects</a>
            <a href="/meeting-schedular">Contact Us</a>
          </>
        ) : (
          <>
            <a href="/home" className={isActive("/home") ? "active" : ""}>Home</a>
            <a href="/about-us" className={isActive("/about-us") ? "active" : ""}>About Us</a>
            <a href="/services" className={isActive("/services") ? "active" : ""}>Our Services</a>
            <a href="/projects" className={isActive("/projects") ? "active" : ""}>Our Projects</a>
            <div className="navbar-button" onClick={() => navigate("/meeting-schedular")}>Contact Us</div>
          </>
        )}
       <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <div className=" nav-logo-btn">Binary Systems</div>
       <button
          className="nav-btn nav-close-btn"
          onClick={showNavbar}>
          <FaTimes />
        </button>
       </div>
        
      </nav>

      <button
        className="nav-btn"
        onClick={showNavbar}>
        <FaBars />
      </button>
    </div>
  );
}

export default Navbar;



import React from 'react'
import './variable.css'
import Variable from '../../../Assets/VariablePrinting.jpg'
const VariablePrinting = () => {
    return (
        <div className='variable-printing'>
            <h2>Variable Data Printing?</h2>
            <div className='about-variabledata'>

                <img src={Variable} alt='' />
                <div className='variable-paragraph'>
                    <p>

                        Variable Data Printing (VDP) is a printing technique that allows for the customization of printed materials by varying elements such as text, images, and graphics from one piece to the next. In the context of cards, such as business cards, loyalty cards, or ID cards, VDP enables the inclusion of unique information on each individual card, such as names, addresses, account numbers, or even personalized images.<br />

                    </p>
                    <p>
                        This technology is particularly valuable in personalized marketing campaigns, where tailored messages and offers can be printed directly onto each card to enhance engagement and response rates. VDP can also be used for security purposes, such as printing unique identification details on access cards or badges.
                    </p>
                    <p>
                        The process of Variable Data Printing typically involves integrating digital printing technology with specialized software that manages the variable content. This allows for seamless production of large volumes of customized cards with minimal setup time and cost, making it an efficient solution for businesses looking to personalize their printed materials.
                    </p>
                </div>
            </div>
            <div className='variable-box'>
                <div className='box'>
                    <div className='box-content'>
                        <h2>01</h2>

                        <p>
                            We specialize in high volume Variable Printing like Electoral Rolls , Students Records, Citizen beneficiaries under different government schemes , Electric / PHED Billing documents, Statement of Transactions, Account Statements, Account Opening Forms, DIS Booklets & all other personalized items etc.</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='box-content'>
                        <h2>02</h2>

                        <p>

                            Client data is meticulously processed using cutting-edge software, converting it into a printable format for Variable Data Printing (VDP). This streamlined approach ensures precise customization of cards, enhancing engagement and effectiveness in targeted marketing campaigns.</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='box-content'>
                        <h2>03</h2>

                        <p>
                            We have the technology for the Variable Data Printing on cards in the form of Embossing, Printing, Indenting etc.This enables tailored communication through personalized cards for various applications such as direct mail and identification.</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='box-content'>
                        <h2>04</h2>

                        <p>
                            We have served our client's with unbelievably huge volumes of Variable Data Printing, within the given scheduled time with utmost accuracy. Complete Security oriented work atmosphere help us to maintain Data Integrity.</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='box-content'>
                        <h2>05</h2>

                        <p>
                            We specialize in innovative technology, sleek design, and meticulous craftsmanship, all powered by state-of-the-art machinery. Our commitment to excellence ensures superior quality and precision in every aspect of our work.</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='box-content'>
                        <h2>06</h2>

                        <p>
                            Our in-house developed and customized software helps us to maintain the complete MIS record about the number of prints, wastages & jams. This helps to retain the confidentiality even during production.</p>
                    </div>
                </div>
            </div>
            <blockquote >
        <p><q>The complete in-house infrastructure helps us to print and supply 10 million pages per month and the same is increasing at regular intervals to meet the client's raised expectations.</q></p>
       
    </blockquote>
        </div>
    )
}

export default VariablePrinting


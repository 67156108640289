import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import './services.css'
import { useNavigate } from 'react-router-dom';
import Connect from '../ContactSection/Connect';
import ServiceHero from '../../Assets/Service-hero.webp'
import RFID from '../../Assets/RFID.jpg'
import DataEntry from '../../Assets/DataEntry.jpg'
import VariablePrinting from '../../Assets/VariablePrinting.jpg'
import Scanning from '../../Assets/Scanning.jpg'
import ERP from '../../Assets/ERP.jpg'
import Governance from '../../Assets/Governance.jpg'
const Services = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
 return (
        <div className='servicePage-container'>

            <div className="service-page">

                <img src={ServiceHero} alt='' />
                <div className='overlay'>
                    <h2>Our Expertise, Your Advantage</h2>
                    <h3> From creation to digitization, explore our comprehensive suite of services designed to meet your business's every need</h3></div>
            </div>

            <div className='servicepage-container'>
                <div className="we-are-block">
                    <h2>PVC/RFID – Card manufacturing</h2>
                    <div id="about-us-section">

                        <div className="about-us-image">
                            <img src={RFID} width="808" height="358" alt="Lobby Image" />
                        </div>
                        <div className="about-us-info">

                            <p>We specialize in the bespoke production of high-quality PVC and RFID cards, tailored to support your business's unique requirements. Utilizing cutting-edge technology and precision engineering, we ensure each card is crafted to perfection, offering durability and functionality.</p>
                            <a href="#" title="About Us Button" onClick={() => { navigate("/pvc-card") }}>Know more</a>
                        </div>
                    </div>
                    <h2>Data Entry & Processing</h2>
                    <div id="history-section">
                        <div className="history-image">
                            <img src={DataEntry} width="951" height="471" alt="Building Pic" />
                        </div>
                        <div className="history-info">

                            <p>Our data entry and processing services are designed to handle high volumes of data with utmost accuracy. We transform raw data into valuable information, providing you with a solid foundation for decision-making and business intelligence.</p>
                            <a href="#" title="History Button" onClick={() => { navigate("/data-entry") }}>Know more</a>
                        </div>
                    </div>
                </div>
                <div className="we-are-block">
                    <h2>Variable Data Printing?</h2>
                    <div id="about-us-section">
                        <div className="about-us-image">
                            <img src={VariablePrinting} width="808" height="458" alt="Lobby Image" />
                        </div>
                        <div className="about-us-info">

                            <p>Variable Data Printing allows for high-level customization of your printed materials. Each piece can be tailored for the individual, making it perfect for personalized cards, serial numbers, or any unique printing needs.</p>
                            <a href="#" title="About Us Button" onClick={() => { navigate("/variable-printing") }}>Know more</a>
                        </div>
                    </div>
                    <h2>Scanning / Digitization</h2>
                    <div id="history-section">
                        <div className="history-image">
                            <img src={Scanning} width="951" height="471" alt="Building Pic" />
                        </div>
                        <div className="history-info">

                            <p>Our scanning and digitization services convert your physical documents into digital formats, making them easily accessible, secure, and ready for the modern age of business.
                            </p>
                            <a href="#" title="History Button" onClick={() => { navigate("/scanning") }}>Know more</a>
                        </div>
                    </div>

                </div>
                <div className="we-are-block">
                    <h2>ERP</h2>
                    <div id="about-us-section">
                        <div className="about-us-image">
                            <img src={ERP} width="808" height="458" alt="Lobby Image" />
                        </div>
                        <div className="about-us-info">

                            <p>We create custom ERP software solutions that streamline your business processes, integrate functions, and provide real-time data insights to enhance efficiency and drive growth</p>
                            <a href="#" title="About Us Button" onClick={() => { navigate("/result-processing") }}>Know more</a>
                        </div>
                    </div>
                    <h2>E-Governance Services</h2>
                    <div id="history-section">
                        <div className="history-image">
                            <img src={Governance} width="951" height="450" alt="Building Pic" />
                        </div>
                        <div className="history-info">

                            <p>Binary Systems is at the forefront of e-Governance, delivering digital services that transform the interaction between the government and the people it serves. Our solutions enhance transparency, streamline governmental operations, and provide citizens with user-friendly, accessible platforms for engagement with public services
                            </p>
                            <a href="#" title="History Button" onClick={() => { navigate("/e-governance") }}>Know more</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='service-contact'>
                <Connect />
            </div>
        </div>
    )
}

export default Services
import React from 'react'
import './topfold.css'
import Topfold1 from '../../../Assets/Topfold1.gif'
import Topfold2 from '../../../Assets/Topfold2.gif'
import Topfold3 from '../../../Assets/Topfold3.gif'
import Topfold4 from '../../../Assets/Topfold4.gif'
const Topfold = () => {
    return (
        <div className='topfold'>
            <div className='tf-left'>
                <div className='tf-heading'>
               <span>  Crafting  Security and Quality</span> into  Every Card
                </div>
                
                 {/* <div className='tf-heading'>
                    Transforming Ideas into Cards:<span> Your Reliable Supplier</span>
                </div> */}
            </div>

            <div className='tf-right'>
                <div className='tf-blob'></div>
                <div className='tf-right-diamond'>
                    <div className='tf-diamond-item'>
                        <img className='tf-r-diamond-img' src={Topfold1} alt='' />
                    </div>
                    <div className='tf-diamond-item'>
                        <img className='tf-r-diamond-img' src={Topfold2} alt='' />
                    </div>
                    <div className='tf-diamond-item'>
                        <img className='tf-r-diamond-img' src={Topfold3} alt='' />
                    </div>
                    <div className='tf-diamond-item'>
                        <img className='tf-r-diamond-img' src={Topfold4} alt='' />
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default Topfold

import React from 'react'
import Digitalization from '../../../Assets/Scanning.jpg'
const Scanning = () => {
    return (
        <div className='data-entry'>
            <h2 style={{ textTransform: 'uppercase',color:'#0c4c91',margin:'10px',fontSize:'28px' }}>Scanning/Digitization</h2>

            <div className='data-about'>

                <div className='data-video'>
                    <img src={Digitalization} style={{ width: '80%', height: '80%' }} alt=''/>
                </div>
                <div className='data-content'>
                In today's age, Digitization has become a necessity for most of the organizations to reduce the dependency on physical storage and space required for the same. Digitization helps in consuming much less space in office and helps much quicker document search and availability, thereby enhancing organization's productivity.

                    <ul className="bulletList">
                        <li> Minimize paper storage & easy document management.</li>
                        <li>Faster availability of information.</li>
                        <li> Archive historic documents.</li>
                        <li> Restrict access to scanned documents as required.</li>
                        <li> Disaster Recovery: Eliminate the risk of lost / misplaced documents due to natural disasters like Fire, Flood, and accidental or intentional deletion etc.</li>
                    </ul>
                    We offer client-specific Document Management Solutions considering the customers present and future needs. With the help of traditional process flow, system of software, we convert the large volume of physical documents and images of any kind, into their digital formats like Jpeg, Tiff, PDFs and PDF/a with various resolutions as per customer's requirement, either for documentation or reproduction purpose.

                </div>
            </div>
        </div>
    )
}

export default Scanning

import React from "react";
import { InlineWidget } from "react-calendly";

const Calendly = () => {
    
  return (
    <div className="calendly" >
      <InlineWidget url="https://calendly.com/umangajmera/meeting-with-binary-systems" />
    </div>
  );
};

export default Calendly;
import React, { useEffect } from 'react'
import './calendly.css'
import { useNavigate } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import Connect from '../../ContactSection/Connect';
const Click = () => {
    let navigate = useNavigate()


    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='click'>
            <div className="horizontal-card">

                <div className="card-text"><h2 className="artifact">Contact Us</h2>
                    <div className='calendly-button' onClick={() => navigate("/calendly")}>Plan Meeting Time »</div>
                </div>
            </div>
            <Connect />
        </div>


    )
}

export default Click

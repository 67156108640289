import React from 'react'
import './client.css'
import ECI from '../../../Assets/ECI.jpg'
import AADHAR from '../../../Assets/Aadhaar_Preview.jpg'
import NPR from '../../../Assets/NPR.jpg'
import EmitraLogo from '../../../Assets/emitra-inner.jpg'
import JanAadhar from '../../../Assets/JAN.jpg'
import ExamProcessing from '../../../Assets/End-to-end ExamProcessing.jpg'
import RKCL from '../../../Assets/rkcl.jpg'
import HDFC from '../../../Assets/HDFC.jpg'
const ClientCarousel = () => {
  return (
    <div className='client'>
      
    <div className='client-carousel'>
      <div className='client-logo'>
        <div className='client-slide'>
            <img src={ECI}/>
        </div>
        <div className='client-slide'>
            <img src={AADHAR}/>
        </div>
        <div className='client-slide'>
        <img src={NPR}/>
        </div>
        
        <div className='client-slide'>
            <img src={EmitraLogo}/>
        </div>
        <div className='client-slide'>
            <img src={JanAadhar}/>
        </div>
        <div className='client-slide'>
            <img src={ExamProcessing}/>
        </div>
        <div className='client-slide'>
            <img src={RKCL}/>
        </div>
        <div className='client-slide'>
            <img src={HDFC}/>
        </div>
        
       {/* ---same slide----- */}
       <div className='client-slide'>
            <img src={ECI}/>
        </div>
        <div className='client-slide'>
            <img src={AADHAR}/>
        </div>
        <div className='client-slide'>
        <img src={NPR}/>
        </div>
        <div className='client-slide'>
            <img src={EmitraLogo}/>
        </div>
        <div className='client-slide'>
            <img src={JanAadhar}/>
        </div>
        <div className='client-slide'>
            <img src={ExamProcessing}/>
        </div>
        <div className='client-slide'>
            <img src={RKCL}/>
        </div>
        <div className='client-slide'>
            <img src={HDFC}/>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ClientCarousel


import React from 'react';
import './connect.css';
import { useNavigate } from "react-router-dom"

import { FaHandshake, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
const Connect = () => {
    let navigate = useNavigate()

  return (
    <div className="connect">
      <h1>Get In Touch</h1>
      <h2>We would love to learn about your project</h2>
      <div className="sections-connect">
        <div className="sections">
          <h3>Meeting Scheduler</h3>
          <p>Mon - Fri: 11 am - 5 pm</p>
          <p>Saturday: 11 am - 3 pm</p>
          <div className='connect-button' onClick={() => navigate("/calendly")}>Schedule<span className="connect-arrow">&#8594;</span></div>
        </div>
        <div className="sections">
          <h3>Email</h3>
          <p>
            Can't connect with us during these hours? We will respond to you via email within a day
          </p>
          <p className='connect-button' >   <FaEnvelope className="icon" style={{marginRight:8}}/>binarysystems125@gmail.com</p>
        </div>
        <div className="sections">
          <h3>Phone & Address</h3>
          <p> For inquiries or assistance, please don't hesitate to contact us.</p>
          <p className='connect-button' > <FaPhone className="icon" style={{marginRight:8}}/> (91) 9314 88 1421</p>
          <p className='connect-button'> <FaMapMarkerAlt className="icon" style={{marginRight:8}}/>H-903, Sitapura Industrial Area, RICO, Jaipur (India)</p>
        </div>
      </div>
    </div>
  );
}

export default Connect;

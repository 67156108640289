import React from 'react'
import '../../App.css'
import Hero from './HeroSection/Hero'
import ServiceContainer from './ServiceContainer/ServiceContainer'
import Slider from './CardDesigns/Cards'
import ClientCarousel from './ClientSection/Client'
const HomePage = () => {
  return (
    <div>
      <Hero />
      <ServiceContainer />
      <Slider />
      <ClientCarousel />
    </div>
  )
}

export default HomePage

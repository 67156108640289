import React from 'react'
import '@fortawesome/fontawesome-free/css/all.css';
import Logo from '../../Assets/BinarySystemsLogo.png'
import './footer.css'
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer className="footer-section">
            <div className="footer-container">


                <div className="footer-row">
                    <div className="footer-widget">
                        <div className="footer-logo">
                            <a href="index.html"><img src={Logo} className="img-fluid" alt="logo" /></a>
                        </div>
                        <div className="footer-text">
                            <p>Binary Systems (GST No. 08ABEPA9306L1ZN)  a proprietorship Firm and has it registered office at H-903, Sitapura Industrial Area, Phase-3, Jaipur – 302022 (Rajasthan)</p>
                        </div>

                    </div>
                    <div className="footer-widget">
                        <div className="footer-widget-heading">
                            <h3>Useful Links</h3>
                        </div>
                        <ul>
                            <li><a href="*" onClick={() => { navigate("/home") }}>Home</a></li>
                            <li><a href="/about-us">About Us</a></li>
                            <li><a href="/services">Our services</a></li>
                            <li><a href="/projects">Major Projects</a></li>
                            <li><a href="/calendly">Contact</a></li>
                            
                        </ul>
                    </div>

                </div>

            </div>
            <div className="copyright-area">
                <p>Copyright &copy; 2022, Binary Systems</p>
            </div>
        </footer>

    )
}

export default Footer





import React from 'react'
import './dataentry.css'
import DataEntry from '../../../Assets/DataEntry.jpg'
import DataProcessing from '../../../Assets/Data-process.jpg'
const Dataentry = () => {
    return (
        <div className='dataentry-processing'>
             <h2>Data Entry?</h2>
            <div className='dataentry'>
                <div className='about-dataentry'>
                   
                    <p>Binary is providing its specialized services in the fields of Data Entry and Bulk Data Entry, to the utmost satisfaction of the clients with high standards of perfection and accuracy.</p>
                    <p>
                        The company has executed successfully several projects of Bulk Data Entry (like 30 million by-lingual electoral rolls etc.) with great competitiveness and accuracy. Hence, earned goodwill in the world of Bulk Data Entry.
                    </p>
                    <p>
                        Binary has a unique in house infrastructure of more than 100 online intelligent data entry systems facilitating data entry and electronically verifying data, keeping higher rate of speed and accuracy.

                        The large installation base at binary gives a capacity to key-in up to 2 lakhs records of 100-character length per day.
                    </p>
                    <p>
                        Binary specializes in the bilingual data entry, i.e. HINDI/ENGLISH.
                    </p>
                </div>
                <div className='dataentry-image'>
                    <img src={DataEntry} alt='' />
                </div>
            </div>
            <h2>Data Processing?</h2>
            <div className='dataprocessing'>
            <div className='dataentry-image'>
                    <img src={DataProcessing} alt='' />
                </div>
                <div className='about-dataentry'>
                    
                   <p>At Binary we offer premier solutions to the clients for their information processing needs. The company has state-of-the-art systems and technologies to provide professional services specializing in data processing and information management.</p>
                <p>
                Binary facilitates the preparation, capture, retrieval and utilization of information for an organization. Data processing is accomplished by extracting information from current/custom designed Forms/Data Sheets/Vouchers/books/Electronic media/Scanned Document Images and converting them into databases, through data entry, specific application software. At the same time, we ensure the utmost integrity, accuracy and security of physical as well as electronic data.
                </p>
                <p>
                Binary assists the clients to reduce their cost & eliminate backlogs significantly by improving data input quality and document availability. This allows your organization to focus on core business and grow exponentially without hiring additional personnel, leasing additional office space and hence, relieves you of investing in costly computer hardware & software which turns obsolete at an increasingly higher rate these days.
                </p>
                <p>
                Binary provides the most cost effective and efficient alternatives to its clients for any business needing assistance with its data management. We have the expertise, experience and resources to handle any type of volume of data entry/data conversion assignments.
                </p>
                </div>
                
            </div>

        </div>
    )
}

export default Dataentry

import React from 'react'
import './resultprocessing.css'
import ERP from '../../../Assets/ERP.jpg'
import Chart from '../../../Assets/chart.png'
import Cheque from '../../../Assets/ChquePersonalization.jpg'
const ResultProcessing = () => {
    return (
        <div className='result-processing'>
            <h2>ERP</h2>
            <div className='result-image'>
            
            <img src={ERP} alt='' />
       
            <img src={Chart} alt='' className='chart' />
            </div>
            <div className='end-to-end'>
                <img src={Cheque} alt='' />
                <div className='result'>
                   <h3>Exam Result Processing Services: </h3>
                   <p>Binary offers comprehensive solutions for exam result processing tailored to the needs of schools, colleges, universities, and private institutions.</p>
                   <h3>
                   Customized Printing Solutions: 
                   </h3>
                   <p>
                   In addition to printing educational stationery, we specialize in producing admit cards, answer books with QR-Codes, OMR (Optical Mark Recognition), and OCR (Optical Character Recognition) forms.
                   </p>
                   <h3>
                   Infrastructure Development: 
                   </h3>
                   <p>
                   To ensure compliance with clients' stringent guidelines and accommodate various customization requirements in result processing, we have invested in developing a robust infrastructure.
                   </p>
                </div>
            </div>
        </div>
    )
}

export default ResultProcessing
